

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from '../alert/alert.service';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class ExpeditionService extends BaseService {

  constructor(public http: HttpClient, alertService: AlertService) {
    super(alertService, http);
  }

  getAllPreExpeditions(queryParams?: {
    [key: string]: any;
  }): Observable<any[]> {
    const defaultParams = {};

    queryParams = {
      ...defaultParams,
      ...queryParams
    };

    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any[]>(`${this.api}/preexpedition`, {
        params
      })
      .pipe(catchError(this.handleError<any[]>('getAll')));
  }

  getPreExpeditionById(preExpeditionId: any): Observable<any> {
    return this.http
      .get(`${this.api}/preexpedition/${preExpeditionId}`)
      .pipe(catchError(this.handleError('getPreExpeditionById')));
  }

  getOrderPreExpedition(queryParams?: {
    [key: string]: any;
  }): Observable<any[]> {
    const defaultParams = {};

    queryParams = {
      ...defaultParams,
      ...queryParams
    };

    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any[]>(`${this.api}/preexpedition/order`, {
        params
      })
      .pipe(catchError(this.handleError<any[]>('getExpedition')));
  }

  postPositions(positionAndWsaida: any): Observable<any> {
    return this.http
      .post<any>(`${this.api}/preexpedition`, positionAndWsaida)
      .pipe(catchError(this.handleError<any>('postPositions')));
  }

  removeOrderPositionPreExpedition(positionAndWsaida: any): Observable<any> {
    return this.http
      .post<any>(`${this.api}/preexpedition/remove`, positionAndWsaida)
      .pipe(catchError(this.handleError<any>('postPositions')));
  }

  changeOrderPositionPreExpedition(positions: any): Observable<any> {
    return this.http
      .post<any>(`${this.api}/preexpedition/move/position/preexpedition`, positions)
      .pipe(catchError(this.handleError<any>('changeOrderPositionPreExpedition')));
  }

  preExpeditionReport(data: any) {
    this.downloadXLS(
      `${this.api}/preexpedition/pre-expedicao-report`, '',
      'Relatório pré expedição'
    );
  }

  printTagExpedition(codwsaida: string) {
    return this.http
      .post(
        `${this.api}/preexpedition/print`,
        {
          codwsaida: codwsaida
        },
        {
          observe: 'response',
          responseType: 'blob' as 'json'
        }
      )
      .pipe(
        map((response: HttpResponse<Blob>) => {
          this.printPdf(response);
          return response.body;
        }),
        catchError(this.handleError<any>('printTagExpedition'))
      );
  }
}
